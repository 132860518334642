import { FC } from "react";

import { IconButtonProps } from "@mui/material";

import { CloseIcon } from "@assets/svg";

import { useIsDesktop } from "@hooks/layout";

import * as Styled from "./styles";

type CloseButtonProps = {
  viewBox?: string;
};

export const CloseButton: FC<IconButtonProps & CloseButtonProps> = (props) => {
  const isDesktop = useIsDesktop();
  const size = isDesktop ? "16px" : "12px";
  return (
    <Styled.Button {...props}>
      <CloseIcon width={size} height={size} viewBox={props.viewBox || "7.22 7.22 17.56 17.56"} />
    </Styled.Button>
  );
};
