import { FC, PropsWithChildren } from "react";

import { LinkProps } from "@mui/material";

import * as Styled from "./styles";

type TLink = { isDisabled?: boolean; href?: string; component?: string; size?: number | string } & PropsWithChildren &
  LinkProps;
export const Link: FC<TLink> = ({
  isDisabled = false,
  href,
  children,
  component,
  onClick,
  size,
  target = "_blank",
  ...restProps
}) => {
  if (component) {
    return (
      <Styled.Link {...restProps} aria-disabled={isDisabled} underline="always" onClick={onClick} size={size}>
        {children}
      </Styled.Link>
    );
  }
  return (
    <Styled.RouterLink to={href}>
      <Styled.Link {...restProps} aria-disabled={isDisabled} underline="hover" target={target} size={size}>
        {children}
      </Styled.Link>
    </Styled.RouterLink>
  );
};
