import { useTranslation } from "react-i18next";
import { toast, ToastOptions, TypeOptions } from "react-toastify";

import { CloseButton } from "@ui/index";

import { CustomToast } from "@components/CustomToast";

export enum ToastStatuses {
  SUCCESS = "success",
  INFO = "info",
  WARNING = "warning",
  ERROR = "error",
  DEFAULT = "default",
}

export const useToast = () => {
  const { t } = useTranslation("toasts");

  const options: ToastOptions = {
    pauseOnHover: true,
    closeOnClick: true,
    theme: "light",
    style: {
      background: "rgba(75, 55, 39, 1) url(./src/assets/avif/common-desktop.avif)",
      border: "1px solid #ffdca8",
    },
  };

  const customToast = (messageKey: string, status: TypeOptions) => {
    toast((toastProps) => <CustomToast closeToast={toastProps.closeToast} message={t(messageKey)} />, {
      ...options,
      closeButton(props) {
        return (
          <CloseButton
            onClick={props.closeToast}
            viewBox="7.22 7.22 25 25"
            sx={{
              "&:hover": {
                "background-color": "transparent  !important",
                "svg path": {
                  fill: "url(#a) !important",
                },
              },
            }}
          />
        );
      },
      type: status,
    });
  };
  return { customToast };
};
