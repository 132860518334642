import React, { FC, useContext, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Box, Stack } from "@mui/material";

import { Button as GradientButton, Link } from "@ui/index";
import { Text } from "@ui/typography";

import { COPY_TEXT, HEADING_FONT_WEIGHT, TEXT_FONT_WEIGHT } from "@pages/FreeUseTerms/constants";

import { useAuth } from "@hooks/auth";

import BorderStack from "@components/BorderStack";
import { VerticalConnector } from "@components/components";
import CreditText from "@components/CreditText";
import { DonationButton } from "@components/DonationButton";
import NewDownloadModal from "@components/DownloadModal";
import { LicenseTestButton } from "@components/LicenseTestButton";
import { QuestionFlowDialog } from "@components/QuestionFlowDialog";
import RequireLicense from "@components/RequireLicense";
import SocialsCarousel from "@components/SocialsCarousel";
import { HandleOpenDialog } from "@layouts/SecondRootLayout";
import { ERoutes } from "@utils/constants";
import { scrollToAnchor } from "@utils/scrollToAnchor";
import { useWindowSize } from "usehooks-ts";

import { CopyIcon } from "@/assets/svg";
import { InfoLink } from "@/components/InfoStack/components";
import LinedTitle from "@/components/LinedTitle";
import RestrictionItem from "@/components/RestrictionItem";
import { useIsDesktop } from "@/hooks/layout";

import { RLink } from "./components";
import { dialogLicenseBlockParts, dialogNeverAllowedKeys, LicenseBlock } from "./constants";

type TFreeUseTermsComponent = {
  isPage?: boolean;
  composer?: string;
  songName?: string;
  videoUrl?: string;
};

const FreeUseTermsComponent: FC<TFreeUseTermsComponent> = ({ isPage = true, composer, songName, videoUrl }) => {
  const { t } = useTranslation("terms");

  const [isCopied, setIsCopied] = useState<boolean>(false);
  const [openQuestionFlow, setOpenQuestionFlow] = useState<boolean>(false);
  const navigate = useNavigate();

  const { isLoggedIn } = useAuth();

  const isDesktop = useIsDesktop();
  const { width } = useWindowSize();

  const handleOpenLoginDialog = useContext(HandleOpenDialog);
  const [donationPopup, setDonationPopup] = useState({ isOpened: false, activeStep: 1 });

  useEffect(() => {
    if (isCopied) {
      const countdownInterval = setInterval(() => {
        setIsCopied(false);
      }, 2000);

      return () => clearInterval(countdownInterval);
    }
  }, [isCopied]);

  const creditTextFormer = () => {
    return `Background Music: ${composer} -  ${songName}\n
       © Provided by Audio Sanctum\n
       Watch: ${videoUrl}\n
       Listen: https://spotify.audiosanctum.com\n
       Free Use Terms → https://audiosanctum.com/free-use`;
  };

  const renderLicense = ({ key, route }: LicenseBlock) => (
    <RequireLicense arrowAlign="start">
      <Text size={0.875} isFullWidth textAlign="left" fontWeight={TEXT_FONT_WEIGHT}>
        <Trans
          t={t}
          i18nKey={key}
          components={[<InfoLink underline="hover" href={route ? route : ""} target="_blank" isInheritSize={true} />]}
        />
      </Text>
    </RequireLicense>
  );

  const renderNotAllowed = (key: string) => (
    <RestrictionItem xMarkAlign="start">
      <Text size={0.875} isFullWidth textAlign="left" fontWeight={TEXT_FONT_WEIGHT}>
        <Trans t={t} i18nKey={key} components={[<InfoLink underline="hover" />]} />
      </Text>
    </RestrictionItem>
  );

  return isPage ? (
    <>
      <Stack width="100%" spacing={isDesktop ? "40px" : "24px"}>
        <BorderStack>
          <Stack
            width={isDesktop ? "75%" : "100%"}
            spacing={isDesktop ? "40px" : "24px"}
            marginBottom={!isDesktop ? "-16px" : undefined}
            alignItems="center"
            justifyContent="center"
            zIndex={5}
          >
            <Stack width="100%" spacing={isDesktop ? "24px" : "16px"}>
              <LinedTitle>
                <Text
                  size={isDesktop ? 1.5 : 1.125}
                  isHeading
                  isFullWidth
                  textAlign="center"
                  fontWeight={TEXT_FONT_WEIGHT}
                >
                  {t("howToUse.mainText")}
                </Text>
              </LinedTitle>

              <Text size={isDesktop ? 1 : 0.875} isFullWidth textAlign="center" fontWeight={TEXT_FONT_WEIGHT}>
                <Trans
                  t={t}
                  i18nKey="howToUse.description"
                  components={[
                    <InfoLink
                      underline="hover"
                      onClick={() => {
                        scrollToAnchor("creditRequirement");
                      }}
                    />,
                    <InfoLink
                      underline="hover"
                      onClick={() => {
                        scrollToAnchor("usesThatRequireLicense");
                      }}
                    />,
                  ]}
                />
              </Text>
            </Stack>

            <SocialsCarousel />
          </Stack>
        </BorderStack>
      </Stack>

      <VerticalConnector />

      <Stack width="100%" spacing={isDesktop ? "40px" : "24px"}>
        <BorderStack>
          <Stack width={isDesktop ? "75%" : "100%"} spacing={isDesktop ? "40px" : "24px"}>
            <Stack width="100%" spacing={isDesktop ? "24px" : "16px"}>
              <LinedTitle>
                <Text
                  size={isDesktop ? 1.5 : 1.125}
                  isHeading
                  isFullWidth
                  textAlign="center"
                  fontWeight={TEXT_FONT_WEIGHT}
                >
                  {t("checkLicenseEligibility.mainText")}
                </Text>
              </LinedTitle>

              <Text size={isDesktop ? 1 : 0.875} isFullWidth textAlign="center" fontWeight={TEXT_FONT_WEIGHT}>
                <Trans t={t} i18nKey="checkLicenseEligibility.description" />
              </Text>
            </Stack>
            (
            <Stack alignItems="center" justifyContent="center">
              <GradientButton isFullWidth={!isDesktop} width={227} onClick={() => setOpenQuestionFlow(true)}>
                {t("checkLicenseEligibility.button")}
              </GradientButton>
            </Stack>
            )
          </Stack>
        </BorderStack>
      </Stack>

      <VerticalConnector />

      <Stack id="creditRequirement" width="100%" spacing={isDesktop ? "40px" : "24px"}>
        <BorderStack>
          <Stack width={isDesktop ? "75%" : "100%"} spacing={isDesktop ? "40px" : "24px"}>
            <Stack width="100%" spacing={isDesktop ? "24px" : "16px"}>
              <LinedTitle>
                <Text
                  size={isDesktop ? 1.5 : 1.125}
                  isHeading
                  isFullWidth
                  textAlign="center"
                  fontWeight={TEXT_FONT_WEIGHT}
                >
                  {t("download.mainText")}
                </Text>
              </LinedTitle>

              <Text size={isDesktop ? 1 : 0.875} isFullWidth textAlign="center" fontWeight={TEXT_FONT_WEIGHT}>
                <Trans t={t} i18nKey="download.description" />
              </Text>
            </Stack>
            (
            <Stack alignItems="center" justifyContent="center">
              <GradientButton
                isFullWidth={!isDesktop}
                width={227}
                isDisabled={isLoggedIn}
                onClick={() => handleOpenLoginDialog(false)}
              >
                {t("download.button")}
              </GradientButton>
            </Stack>
            )
          </Stack>
        </BorderStack>
      </Stack>

      <VerticalConnector />

      <Stack width="100%" spacing={isDesktop ? "40px" : "24px"}>
        <BorderStack>
          <Stack
            width={isDesktop ? "75%" : "100%"}
            spacing={isDesktop ? "40px" : "24px"}
            alignItems="center"
            justifyContent="center"
            zIndex={5}
          >
            <Stack width="100%" spacing={isDesktop ? "24px" : "16px"}>
              <LinedTitle>
                <Text
                  size={isDesktop ? 1.5 : 1.125}
                  isHeading
                  isFullWidth
                  textAlign="center"
                  fontWeight={TEXT_FONT_WEIGHT}
                >
                  {t("credit.mainText")}
                </Text>
              </LinedTitle>

              <Text size={isDesktop ? 1 : 0.875} isFullWidth textAlign="center" fontWeight={TEXT_FONT_WEIGHT}>
                <Trans t={t} i18nKey="credit.description" />
              </Text>
            </Stack>

            <Stack spacing={isDesktop ? "16px" : "8px"} width="100%" alignItems="center" justifyContent="center">
              <Stack width={isDesktop ? "55%" : "100%"}>
                <BorderStack>
                  <Stack
                    width="100%"
                    height="100%"
                    alignItems="center"
                    justifyContent="center"
                    spacing={isDesktop ? "24px" : "16px"}
                    zIndex={5}
                  >
                    <Text size={isDesktop ? 1 : 0.875} isFullWidth textAlign="center" fontWeight={TEXT_FONT_WEIGHT}>
                      <CreditText composer={composer} songName={songName} videoUrl={videoUrl} />
                    </Text>

                    <GradientButton
                      isFullWidth={!isDesktop}
                      isOutlined
                      width={234}
                      onClick={() => {
                        setIsCopied(true);
                        navigator.clipboard.writeText(COPY_TEXT);
                      }}
                    >
                      <Stack direction="row" spacing="8px" alignItems="center" justifyContent="center">
                        <CopyIcon />
                        <Box>{t(isCopied ? "credit.buttonClicked" : "credit.button")}</Box>
                      </Stack>
                    </GradientButton>
                  </Stack>
                </BorderStack>
              </Stack>
            </Stack>
          </Stack>
        </BorderStack>
      </Stack>

      <VerticalConnector />

      <Stack id="usesThatRequireLicense" width="100%" spacing={isDesktop ? "40px" : "24px"}>
        <BorderStack>
          <Stack
            width="100%"
            spacing={isDesktop ? "40px" : "24px"}
            alignItems="center"
            justifyContent="center"
            zIndex={5}
          >
            <LinedTitle>
              <Text
                size={isDesktop ? 1.5 : 1.125}
                isHeading
                isFullWidth
                textAlign="center"
                fontWeight={TEXT_FONT_WEIGHT}
              >
                {t("licenseRequire.mainText")}
              </Text>
            </LinedTitle>

            <Stack width="100%" spacing={isDesktop ? "24px" : "16px"}>
              <RequireLicense>
                <Text size={isDesktop ? 1 : 0.875} isFullWidth textAlign="center" fontWeight={TEXT_FONT_WEIGHT}>
                  <Trans t={t} i18nKey="licenseRequire.first" components={[<InfoLink underline="hover" />]} />
                </Text>
              </RequireLicense>
              <RequireLicense>
                <Text size={isDesktop ? 1 : 0.875} isFullWidth textAlign="center" fontWeight={TEXT_FONT_WEIGHT}>
                  <Trans t={t} i18nKey="licenseRequire.second" components={[<InfoLink underline="hover" />]} />
                </Text>
              </RequireLicense>
              <RequireLicense>
                <Text size={isDesktop ? 1 : 0.875} isFullWidth textAlign="center" fontWeight={TEXT_FONT_WEIGHT}>
                  <Trans t={t} i18nKey="licenseRequire.third" components={[<InfoLink underline="hover" />]} />
                </Text>
              </RequireLicense>
            </Stack>
            <Stack alignItems="center" justifyContent="center">
              <GradientButton
                isFullWidth={!isDesktop}
                width={169}
                onClick={() => navigate(ERoutes.CatalogLandingLicensing)}
              >
                {t("licenseRequire.button")}
              </GradientButton>
            </Stack>
          </Stack>
        </BorderStack>
      </Stack>

      <VerticalConnector />

      <Stack width="100%" spacing={isDesktop ? "40px" : "24px"}>
        <BorderStack>
          <Stack
            width="100%"
            spacing={isDesktop ? "40px" : "24px"}
            alignItems="center"
            justifyContent="center"
            zIndex={5}
          >
            <LinedTitle>
              <Text
                size={isDesktop ? 1.5 : 1.125}
                isHeading
                isFullWidth
                textAlign="center"
                fontWeight={TEXT_FONT_WEIGHT}
              >
                {t("neverAllowed.mainText")}
              </Text>
            </LinedTitle>

            <Stack width="100%" spacing={isDesktop ? "24px" : "16px"}>
              <RestrictionItem>
                <Text size={isDesktop ? 1 : 0.875} isFullWidth textAlign="center" fontWeight={TEXT_FONT_WEIGHT}>
                  <Trans t={t} i18nKey="neverAllowed.first" components={[<InfoLink underline="hover" />]} />
                </Text>
              </RestrictionItem>
              <RestrictionItem>
                <Text size={isDesktop ? 1 : 0.875} isFullWidth textAlign="center" fontWeight={TEXT_FONT_WEIGHT}>
                  <Trans t={t} i18nKey="neverAllowed.second" components={[<InfoLink underline="hover" />]} />
                </Text>
              </RestrictionItem>
            </Stack>
          </Stack>
        </BorderStack>
      </Stack>

      {isDesktop && (
        <Stack textAlign="center" marginY={isDesktop ? "80px" : "40px"}>
          (
          <Text size={isDesktop ? 1.3 : 1} isFullWidth textAlign="center" fontWeight={TEXT_FONT_WEIGHT}>
            <Trans
              t={t}
              i18nKey="questions"
              components={[
                <Link
                  component="button"
                  size="inherit"
                  onClick={() => {
                    scrollToAnchor("musicFAQ");
                  }}
                />,
                <Link size="inherit" href={ERoutes.Contact} />,
              ]}
            />
          </Text>
        </Stack>
      )}
      <QuestionFlowDialog open={openQuestionFlow} onClose={() => setOpenQuestionFlow(false)} />
    </>
  ) : (
    <>
      <Stack width="100%" spacing={isDesktop ? "40px" : "24px"}>
        <Stack
          width={"100%"}
          spacing={isDesktop ? "40px" : "24px"}
          marginBottom={!isDesktop ? "-16px" : undefined}
          alignItems="center"
          justifyContent="center"
          zIndex={5}
        >
          <Stack width="100%" spacing={isDesktop ? "24px" : "16px"}>
            <Text size={1.5} isHeading isFullWidth textAlign="center" fontWeight={TEXT_FONT_WEIGHT}>
              {t("dialog.mainText")}
            </Text>

            <Text size={1} isFullWidth textAlign="center" fontWeight={TEXT_FONT_WEIGHT}>
              <Trans
                t={t}
                i18nKey="dialog.description"
                components={[
                  <InfoLink
                    underline="hover"
                    onClick={() => {
                      scrollToAnchor("creditRequirement");
                    }}
                    isInheritSize={true}
                  />,
                  <InfoLink
                    underline="hover"
                    onClick={() => {
                      scrollToAnchor("usesThatRequireLicense");
                    }}
                    isInheritSize={true}
                  />,
                ]}
              />
            </Text>
          </Stack>

          <SocialsCarousel sx={{ padding: "12px 0" }} />
        </Stack>
      </Stack>

      <Stack width="100%" spacing={isDesktop ? "40px" : "24px"} margin={"40px 0 0 0"}>
        <BorderStack innerSx={{ padding: "24px !important" }}>
          <Stack width={"100%"} gap={"16px"} zIndex={5}>
            <Stack width="100%" gap={"16px"}>
              <Text size={1.125} isHeading isFullWidth textAlign="center" fontWeight={TEXT_FONT_WEIGHT}>
                {t("dialog.checkQuality.mainText")}
              </Text>

              <Text size={0.875} isFullWidth textAlign="center" fontWeight={TEXT_FONT_WEIGHT}>
                <Trans
                  t={t}
                  i18nKey="dialog.checkQuality.description"
                  components={[
                    <InfoLink
                      underline="hover"
                      onClick={() => {
                        scrollToAnchor("usesThatRequireLicense");
                      }}
                      isInheritSize={true}
                    />,
                  ]}
                />
              </Text>
            </Stack>

            <Stack alignItems="center" justifyContent="center">
              <LicenseTestButton
                isFullWidth={!isDesktop}
                handler={() => setOpenQuestionFlow(true)}
                sx={{ width: width < 420 ? "fit-content !important" : "", gap: width < 420 ? "16px" : "5px" }}
              >
                <Trans t={t} i18nKey="dialog.checkQuality.button" components={[width < 420 ? <br /> : <span />]} />
              </LicenseTestButton>
            </Stack>
          </Stack>
        </BorderStack>
      </Stack>

      <VerticalConnector />

      <Stack width="100%" spacing={isDesktop ? "40px" : "24px"}>
        <BorderStack innerSx={{ padding: "24px !important" }}>
          <Stack width={"100%"} gap={"16px"} zIndex={5}>
            <Stack width="100%" gap={"16px"}>
              <Text size={1.125} isHeading isFullWidth textAlign="center" fontWeight={TEXT_FONT_WEIGHT}>
                {t("dialog.download.mainText")}
              </Text>

              <Text size={0.875} isFullWidth textAlign="center" fontWeight={TEXT_FONT_WEIGHT}>
                <Trans t={t} i18nKey="dialog.download.description" />
              </Text>
            </Stack>

            <Stack alignItems="center" justifyContent="center">
              <GradientButton
                isFullWidth={!isDesktop}
                isDisabled={isLoggedIn}
                onClick={() => handleOpenLoginDialog(false)}
                sx={{ lineHeight: "112.5% !important", padding: `24px ${!isDesktop ? "0" : "98px"} !important` }}
              >
                {t("dialog.download.button")}
              </GradientButton>
            </Stack>
          </Stack>
        </BorderStack>
      </Stack>

      <VerticalConnector />

      <Stack id="creditRequirement" width="100%" spacing={isDesktop ? "40px" : "24px"}>
        <BorderStack innerSx={{ padding: "24px !important" }}>
          <Stack width={"100%"} gap={"16px"} alignItems="center" justifyContent="center" zIndex={5}>
            <Stack width="100%" gap={"16px"}>
              <Text size={1.125} isHeading isFullWidth textAlign="center" fontWeight={TEXT_FONT_WEIGHT}>
                {t("dialog.credit.mainText")}
              </Text>

              <Text size={0.875} isFullWidth textAlign="center" fontWeight={TEXT_FONT_WEIGHT}>
                <Trans t={t} i18nKey="dialog.credit.description" />
              </Text>
            </Stack>

            <Stack spacing={isDesktop ? "16px" : "8px"} width="100%" alignItems="center" justifyContent="center">
              <Stack width={"100%"}>
                <Stack
                  width="100%"
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                  spacing={isDesktop ? "24px" : "16px"}
                  zIndex={5}
                >
                  <Text size={0.75} isFullWidth textAlign="center" fontWeight={TEXT_FONT_WEIGHT}>
                    <CreditText
                      composer={composer}
                      songName={songName}
                      videoUrl={videoUrl}
                      sx={{ fontSize: "inherit !important" }}
                    />
                  </Text>

                  <GradientButton
                    isFullWidth={!isDesktop}
                    isOutlined
                    width={234}
                    onClick={() => {
                      setIsCopied(true);
                      navigator.clipboard.writeText(creditTextFormer());
                    }}
                    sx={{ lineHeight: "112.5% !important", fontSize: "0.875rem", fontWeigh: HEADING_FONT_WEIGHT }}
                  >
                    <Stack direction="row" spacing="8px" alignItems="center" justifyContent="center">
                      <CopyIcon />
                      <Box>{t(isCopied ? "dialog.credit.buttonClicked" : "dialog.credit.button")}</Box>
                    </Stack>
                  </GradientButton>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </BorderStack>
      </Stack>

      <VerticalConnector />

      <Stack width="100%">
        <BorderStack innerSx={{ padding: "24px !important" }}>
          <Stack width="100%" gap={"16px"} alignItems="center" justifyContent="center" zIndex={5}>
            <Text size={1.125} isHeading isFullWidth textAlign="center" fontWeight={TEXT_FONT_WEIGHT}>
              <Trans t={t} i18nKey="dialog.content.mainText" />
            </Text>

            <Text size={0.875} isFullWidth textAlign="center" fontWeight={TEXT_FONT_WEIGHT}>
              <Trans
                t={t}
                i18nKey="dialog.content.description"
                components={[
                  <InfoLink
                    underline="hover"
                    href={"/contact-licensing/contact"}
                    target="_blank"
                    isInheritSize={true}
                  />,
                ]}
              />
            </Text>
          </Stack>
        </BorderStack>
      </Stack>

      <VerticalConnector />

      <Stack width="100%">
        <BorderStack innerSx={{ padding: "24px !important" }}>
          <Stack width={"100%"} gap={"16px"} zIndex={5}>
            <Stack width="100%" gap={"16px"}>
              <Text size={1.125} isHeading isFullWidth textAlign="center" fontWeight={TEXT_FONT_WEIGHT}>
                {t("dialog.donation.mainText")}
              </Text>

              <Text size={0.875} isFullWidth textAlign="center" fontWeight={TEXT_FONT_WEIGHT}>
                <Trans
                  t={t}
                  i18nKey="dialog.donation.description"
                  components={[
                    <InfoLink
                      underline="hover"
                      onClick={() => {
                        setOpenQuestionFlow(true);
                      }}
                      sx={{ fontSize: "0.875rem !important" }}
                    />,
                    <InfoLink
                      underline="hover"
                      href={ERoutes.CatalogLandingLicensing}
                      target="_blank"
                      sx={{ fontSize: "0.875rem !important" }}
                    />,
                    <InfoLink
                      underline="hover"
                      href={ERoutes.FreeUseTerms}
                      target="_blank"
                      sx={{ fontSize: "0.875rem !important" }}
                    />,
                  ]}
                />
              </Text>
            </Stack>

            <Stack alignItems="center" justifyContent="center">
              <DonationButton
                isFullWidth={!isDesktop}
                handler={() => setDonationPopup((prev) => ({ ...prev, isOpened: true }))}
                sx={{ lineHeight: "112.5% !important", padding: width >= 500 ? "8px 76px !important" : "" }}
              >
                {t("dialog.donation.button")}
              </DonationButton>
            </Stack>
          </Stack>
        </BorderStack>
      </Stack>
      {/* <>------------------------------------------------------------</> */}

      <Stack id="usesThatRequireLicense" width="100%" sx={{ margin: "40px 0 0 0" }}>
        <BorderStack
          withoutBorder
          outerSx={{
            background: "linear-gradient(0deg, rgba(59, 45, 33, 0.375) 0%, rgba(58, 44, 36, 0.75) 100%)",
            borderRadius: "16px",
          }}
          innerSx={{ padding: "24px !important" }}
        >
          <Stack width="100%" spacing={"16px"} alignItems="center" justifyContent="center" zIndex={5}>
            <Text size={1.125} isHeading isFullWidth textAlign="center" fontWeight={HEADING_FONT_WEIGHT}>
              {t("licenseRequire.mainText")}
            </Text>

            <Stack width="100%" spacing={"8px"}>
              {dialogLicenseBlockParts.map(renderLicense)}
            </Stack>

            <Stack flexDirection="row" width="100%" alignItems="center" justifyContent="center" gap="16px">
              <RLink to={ERoutes.Licensing} target="_blank">
                <GradientButton
                  isFullWidth={!isDesktop}
                  sx={{
                    padding: width >= 500 ? `16px ${isDesktop ? 48 : 24}px !important` : "",
                    fontSize: "0.875rem",
                    fontWeigh: HEADING_FONT_WEIGHT,
                    height: isDesktop ? "fit-content !important" : "100% !important",
                  }}
                >
                  {t("dialog.licenseRequire.button")}
                </GradientButton>
              </RLink>

              <GradientButton
                isFullWidth={!isDesktop}
                onClick={() => navigate(ERoutes.MUSIC_CREATOR_PROGRAM)}
                sx={{
                  padding: width >= 500 ? `16px !important` : "",
                  fontSize: "0.875rem",
                  fontWeigh: HEADING_FONT_WEIGHT,
                  height: "fit-content !important",
                }}
              >
                {t("dialog.licenseRequire.secondButton")}
              </GradientButton>
            </Stack>
          </Stack>
        </BorderStack>
      </Stack>

      <Stack width="100%" sx={{ margin: "40px 0 0 0" }}>
        <BorderStack
          withoutBorder
          outerSx={{
            background: "linear-gradient(0deg, rgba(59, 45, 33, 0.375) 0%, rgba(58, 44, 36, 0.75) 100%)",
            borderRadius: "16px",
          }}
          innerSx={{ padding: "24px !important" }}
        >
          <Stack width="100%" spacing={"24px"} alignItems="center" justifyContent="center" zIndex={5}>
            <Text size={1.125} isHeading isFullWidth textAlign="center" fontWeight={HEADING_FONT_WEIGHT}>
              {t("dialog.neverAllowed.mainText")}
            </Text>

            <Stack width="100%" spacing={"8px"}>
              {dialogNeverAllowedKeys.map(renderNotAllowed)}
            </Stack>
          </Stack>
        </BorderStack>
      </Stack>

      <Stack textAlign="center" sx={{ margin: "40px 0 0 0", padding: "0 0 16px 0" }} zIndex={5}>
        <Text size={0.875} isFullWidth textAlign="center" fontWeight={TEXT_FONT_WEIGHT}>
          <Trans
            t={t}
            i18nKey="dialog.questions"
            components={[
              <Link
                component="button"
                size="inherit"
                onClick={() => {
                  scrollToAnchor("musicFAQ");
                }}
              />,
              <Link size="inherit" href={ERoutes.Contact} target="_blank" />,
            ]}
          />
        </Text>
      </Stack>
      <QuestionFlowDialog open={openQuestionFlow} onClose={() => setOpenQuestionFlow(false)} />
      <NewDownloadModal
        open={donationPopup.isOpened}
        onClose={() => setDonationPopup((prev) => ({ ...prev, isOpened: false }))}
        copyrightModal={true}
        activeStep={donationPopup.activeStep}
        handleNext={() => setDonationPopup((prev) => ({ ...prev, activeStep: prev.activeStep + 1 }))}
        composer=""
      />
    </>
  );
};

export default FreeUseTermsComponent;
