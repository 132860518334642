import React, { FC } from "react";

import { CloseIcon } from "@assets/svg";

import { Player } from "./components";
import * as Styled from "./styles";

type TBottomPlayer = {
  handleCloseTrack: () => void;
};

export const BottomPlayer: FC<TBottomPlayer> = ({ handleCloseTrack }) => {
  return (
    <Styled.BottomPlayer>
      <Styled.CloseButtonWrapper>
        <Styled.CloseButton onClick={handleCloseTrack}>
          <CloseIcon />
        </Styled.CloseButton>
      </Styled.CloseButtonWrapper>
      <Player handleCloseTrack={handleCloseTrack} />
    </Styled.BottomPlayer>
  );
};
