import React, { FC } from "react";
import { Trans, useTranslation } from "react-i18next";

import { Stack } from "@mui/material";

import { WelcomeLogo } from "@assets/svg";

import { useIsDesktop } from "@hooks/layout";

import { DescriptionTypography } from "@components/LoginDialog/components";
import { MainTextTypography } from "@components/RecoveryDialog/components";
import { ERoutes } from "@utils/constants";

import { Button as GradientButton, Link } from "@/ui-kit";

type TWelcome = {
  handleClose: () => void;
};

const Welcome: FC<TWelcome> = ({ handleClose }) => {
  const { t } = useTranslation("signup", {
    keyPrefix: "welcome",
  });

  const isDesktop = useIsDesktop();
  return (
    <Stack spacing={isDesktop ? "24px" : "16px"} width="100%" alignItems="center" justifyContent="center">
      <WelcomeLogo style={{ width: isDesktop ? "64px" : "48px", height: "auto" }} />
      <Stack spacing="16px" height="100%" width="100%">
        <Stack spacing="16px" justifyContent="center" alignItems="center">
          <MainTextTypography>{t("mainText")}</MainTextTypography>

          <DescriptionTypography>
            <Trans
              t={t}
              i18nKey="description"
              components={[
                <Link size="inherit" href={ERoutes.Catalog} />,
                <span style={{ color: "#FFDCA8" }} />,
                <Link size="inherit" href={ERoutes.Deals} />,
                <Link size="inherit" href={"/contact-licensing/contact"} />,
              ]}
            />
          </DescriptionTypography>
        </Stack>
      </Stack>

      <GradientButton onClick={handleClose} isFullWidth>
        {t("buttonText")}
      </GradientButton>
    </Stack>
  );
};

export default Welcome;
