import React, { FC, Fragment } from "react";
import { useNavigate } from "react-router-dom";

import { Stack } from "@mui/material";

import { AlbumAuthorDetails, AlbumAuthorName } from "@pages/Catalog/components/components";

import { FiltersType, navigateToCatalogWithQuery } from "@hooks/useFiltersQuery/utils";

import { IComposer } from "@models/models";
import uniqueId from "lodash/uniqueId";

import { Avatar } from "@/ui-kit";

import * as Styled from "./styles";

type TAvatarGroup = {
  composers: IComposer[] | string[];
  isSmall?: boolean;
  isCollapsed?: boolean;
  isNamesHidden?: boolean;
  isDetails?: boolean;
  doesNeedAlignment?: boolean;
  isSmallDesktop?: boolean;
};

export const AvatarGroup: FC<TAvatarGroup> = ({
  composers,
  isSmall = false,
  isCollapsed = false,
  isNamesHidden = false,
  isDetails = false,
  doesNeedAlignment = false,
  isSmallDesktop = false,
}) => {
  const navigate = useNavigate();

  const handleNavigateComposer = (composerName?: string) => {
    if (!composerName) return;
    navigate(navigateToCatalogWithQuery(composerName, FiltersType.COMPOSER));
  };

  if (!composers) {
    return null;
  }

  if (composers?.length === 1) {
    return (
      <Avatar
        doesNeedAlignment={doesNeedAlignment}
        composerName={composers[0] as unknown as string}
        isSmall={isSmall}
        isSmallDesktop={isSmallDesktop}
        onClick={() => handleNavigateComposer(typeof composers[0] === "object" ? composers[0].name : composers[0])}
      >
        {isDetails ? (
          <AlbumAuthorDetails
            underline="hover"
            onClick={() => handleNavigateComposer(typeof composers[0] === "object" ? composers[0].name : composers[0])}
            width={isSmallDesktop ? "35%" : "fit-content"}
          >
            {typeof composers[0] === "object" ? composers[0].name : composers[0]}
          </AlbumAuthorDetails>
        ) : (
          <AlbumAuthorName
            onClick={() => handleNavigateComposer(typeof composers[0] === "object" ? composers[0].name : composers[0])}
          >
            {typeof composers[0] === "object" ? composers[0].name : composers[0]}
          </AlbumAuthorName>
        )}
      </Avatar>
    );
  }

  return (
    <Stack direction="row" alignItems="center" spacing="8px">
      <Styled.AvatarGroupWrapper $isSmall={isSmall} max={3} total={composers.length}>
        {composers.map((composer) => (
          <Avatar
            onClick={() => handleNavigateComposer(composer.name)}
            composerName={composer.name}
            key={uniqueId("avatar-group_")}
            isSmall={isSmall}
            isGroup
          />
        ))}
      </Styled.AvatarGroupWrapper>
      {!isCollapsed && (
        <Stack direction="row" alignItems="center" spacing="4px">
          {composers.length > 3 || isNamesHidden
            ? null
            : composers.map((composer, index) => (
                <Fragment key={uniqueId(`${composer}_`)}>
                  {isDetails ? (
                    <AlbumAuthorDetails underline="hover" onClick={() => handleNavigateComposer(composer.name)}>
                      {composer.name}
                    </AlbumAuthorDetails>
                  ) : (
                    <AlbumAuthorName onClick={() => handleNavigateComposer(composer.name)}>
                      {composer.name}
                    </AlbumAuthorName>
                  )}
                  {index !== composers.length - 1 && <span style={{ color: "#FBF4EC" }}>&</span>}
                </Fragment>
              ))}
        </Stack>
      )}
    </Stack>
  );
};
