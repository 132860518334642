import { FC, PropsWithChildren } from "react";

import { Stack } from "@mui/material";

import { ArrowRightIco } from "@/assets/svg";
import { useIsDesktop } from "@/hooks/layout";

type Props = {
  arrowAlign?: "start" | "end" | "center";
};

const RequireLicense: FC<Props & PropsWithChildren> = ({ children, arrowAlign = "center" }) => {
  const isDesktop = useIsDesktop();

  return (
    <Stack
      width="100%"
      height="100%"
      direction="row"
      alignItems={arrowAlign}
      justifyContent="start"
      spacing={isDesktop ? "16px" : "8px"}
    >
      <Stack
        padding="4px"
        height="26px"
        sx={{ backgroundColor: "#3C2D23", borderRadius: "50%" }}
        alignItems={arrowAlign}
        justifyContent="center"
      >
        <ArrowRightIco />
      </Stack>
      {children}
    </Stack>
  );
};

export default RequireLicense;
