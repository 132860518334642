import { isSmallDesktop } from "@hooks/layout";

import styled from "styled-components";

export const AdvantageContainer = styled.div`
  width: calc(50% - 8px);
  padding: 16px 28px 12px;

  background-color: rgb(75 55 39 / 80);

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  ${isSmallDesktop} {
    grid-column: span 1;
    padding: 16px;
  }
`;
