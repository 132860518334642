import { FC, ReactNode } from "react";

import { Box, Stack } from "@mui/material";

import { useIsDesktop } from "@/hooks/layout";
import { CloseButton } from "@/ui-kit";

import { CustomDialog, ScrollWrapper, StyledBackDrop } from "./components";

interface IGradientDialog {
  open: boolean;
  children: ReactNode;
  handleClose: () => void;
  scroll?: "body" | "paper";
  isClosable?: boolean;
  background?: string;
}

const GradientDialog: FC<IGradientDialog> = ({
  open,
  children,
  handleClose,
  scroll,
  isClosable = true,
  background,
}) => {
  const isDesktop = useIsDesktop();

  return (
    <CustomDialog open={open} BackdropComponent={StyledBackDrop} scroll={scroll}>
      <Stack
        sx={{
          background: background
            ? background
            : "linear-gradient(to right bottom, rgba(51,38,28,1) 0%, rgba(79,57,38,1) 100%)",
          padding: "5px",
        }}
        width="100%"
      >
        {isClosable && (
          <Box width="100%" position="relative" zIndex={10}>
            <Box position="absolute" sx={{ top: 0, right: 0, transform: "translate(-25%, 25%)" }}>
              <CloseButton onClick={handleClose} />
            </Box>
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            paddingTop: isDesktop ? "40px" : "24px",
            paddingBottom: isDesktop ? "40px" : "24px",
          }}
        >
          <ScrollWrapper sx={{ paddingLeft: isDesktop ? "40px" : "24px", paddingRight: isDesktop ? "40px" : "24px" }}>
            {children}
          </ScrollWrapper>
        </Box>
      </Stack>
    </CustomDialog>
  );
};

export default GradientDialog;
