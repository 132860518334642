import { Box, Divider } from "@mui/material";

import styled from "styled-components";

import { isSmallDesktop } from "@/hooks/layout";

export const StyledDivider = styled(Divider)<{ $isFooter?: boolean; $isPage?: boolean; $isHeader?: boolean }>`
  width: 100%;
  height: ${({ $isFooter, $isHeader }) => ($isFooter || $isHeader ? "2px" : "3px")};
  z-index: 5;
  position: absolute;
  bottom: ${({ $isFooter }) => ($isFooter ? "unset" : 0)};
  top: ${({ $isFooter }) => ($isFooter ? 0 : "unset")};

  background: linear-gradient(
    93deg,
    rgba(186, 144, 92, 0.5) 0.82%,
    #ffd29b 33.73%,
    #ffe0b1 47.65%,
    #ffd29b 61.53%,
    rgba(186, 144, 92, 0.5) 99.48%
  );

  ${isSmallDesktop} {
    height: ${({ $isFooter, $isHeader }) => ($isFooter || $isHeader ? "2px" : "3px")};
  }
`;

export const VerticalConnector = styled(Box)`
  height: 40px;
  min-height: 40px;
  width: 50%;

  align-self: flex-start;

  border: 2px solid;
  border-image-slice: 1;

  border-left: 0;
  border-bottom: 0;
  border-top: 0;

  border-image-source: linear-gradient(
    0deg,
    rgba(253, 209, 154, 1) 0%,
    rgba(255, 210, 155, 1) 40%,
    rgba(255, 224, 177, 1) 50%,
    rgba(255, 210, 155, 1) 60%,
    rgba(186, 144, 92, 1) 100%
  );

  ${isSmallDesktop} {
    height: 56px;
  }
`;
