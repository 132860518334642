export enum SongMenuPhrases {
  COPY = "songMenu.copy",
}

export enum TrackPhrases {
  ADDED = "track.add",
  REMOVED = "track.remove",
}

export enum AlbumPhrases {
  ADDED = "album.add",
  REMOVED = "album.remove",
}
