import { ERoutes } from "@utils/constants";

export type LicenseBlock = {
  key: string;
  route: string;
};

export const licenseBlockPartsKeys = ["licenseRequire.first", "licenseRequire.second", "licenseRequire.third"];

export const dialogLicenseBlockParts: LicenseBlock[] = [
  { key: "dialog.licenseRequire.first", route: "" },
  { key: "dialog.licenseRequire.second", route: ERoutes.MUSIC_CREATOR_PROGRAM },
  { key: "dialog.licenseRequire.third", route: "" },
];

export const dialogNeverAllowedKeys = [
  "dialog.neverAllowed.first",
  "dialog.neverAllowed.second",
  "dialog.neverAllowed.third",
];
