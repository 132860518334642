import { FC, JSXElementConstructor, ReactElement } from "react";

import { ClickAwayListener, Fade, Paper, Popper, PopperPlacementType } from "@mui/material";

import { bindPopper } from "material-ui-popup-state";
import { PopupState } from "material-ui-popup-state/hooks";

type TMenu = {
  popupState: PopupState;
  offset?: [number, number];
  children: ReactElement<any, string | JSXElementConstructor<any>>;
  paperProps?: Record<string, unknown>;
  placement?: PopperPlacementType;
  zIndex?: number;
};

export const Menu: FC<TMenu> = ({ popupState, offset = [0, 0], children, paperProps, placement, zIndex = 999 }) => {
  return (
    <Popper
      {...bindPopper(popupState)}
      transition
      placement={placement}
      sx={{ zIndex }}
      modifiers={[
        {
          name: "offset",
          options: {
            offset,
          },
        },
      ]}
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Paper {...paperProps}>
            <ClickAwayListener onClickAway={popupState.close}>{children}</ClickAwayListener>
          </Paper>
        </Fade>
      )}
    </Popper>
  );
};
