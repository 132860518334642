export const isIOS = () => {
  return /iPhone|iPad|iPod/i.test(navigator.userAgent);
};

export const isSafari = () => {
  const userAgent = navigator.userAgent;
  const isSafariBrowser = /^((?!chrome|android).)*safari/i.test(userAgent);

  return isSafariBrowser;
};
