import { FC, ReactNode } from "react";

import { SxProps } from "@mui/material";

import { InnerBorderStack, OuterBorderStack } from "@/components/BorderStack/components";

interface IBorderStack {
  children: ReactNode;
  isProfilePage?: boolean;
  outerSx?: SxProps;
  innerSx?: SxProps;
  withoutBorder?: boolean;
  isWhiteBorder?: boolean;
  withPadding?: boolean;
}

const BorderStack: FC<IBorderStack> = ({
  children,
  isProfilePage = false,
  withoutBorder = false,
  isWhiteBorder = false,
  withPadding = false,
  innerSx,
  outerSx,
}) => {
  return (
    <OuterBorderStack
      $withoutBorder={withoutBorder}
      $isWhiteBorder={isWhiteBorder}
      $withPadding={withPadding}
      sx={outerSx}
    >
      <InnerBorderStack
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        $isProfilePage={isProfilePage}
        $withoutBorder={withoutBorder}
        sx={innerSx}
      >
        {children}
      </InnerBorderStack>
    </OuterBorderStack>
  );
};

export default BorderStack;
