import { FC, PropsWithChildren } from "react";

import { Stack } from "@mui/material";

import { RestrictIcon } from "@/assets/svg";
import { useIsDesktop } from "@/hooks/layout";

type Props = {
  xMarkAlign?: "start" | "end" | "center";
};

const RestrictionItem: FC<Props & PropsWithChildren> = ({ children, xMarkAlign = "center" }) => {
  const isDesktop = useIsDesktop();

  return (
    <Stack
      width="100%"
      direction="row"
      alignItems={xMarkAlign}
      justifyContent="start"
      spacing={isDesktop ? "16px" : "8px"}
    >
      <Stack padding="4px" sx={{ backgroundColor: "#3C2D23", borderRadius: "100px" }}>
        <RestrictIcon />
      </Stack>
      {children}
    </Stack>
  );
};

export default RestrictionItem;
