import React, { FC, ReactNode } from "react";

import { StyledDivider } from "@components/components";

import * as Styled from "./styles";
import { setBackgroundImageStyle } from "./utils";

type TPageContainer = {
  isPage?: boolean;
  backgroundImage: string;
  children: ReactNode;
  withBanner?: boolean;
  withDivider?: boolean;
  isCentered?: boolean;
  isHero?: boolean;
  id?: string;
  minHeight?: number;
  isBackgroundPositionBottom?: boolean;
  style?: React.CSSProperties;
};

export const PageContainer: FC<TPageContainer> = ({
  isPage = false,
  backgroundImage,
  children,
  withBanner = false,
  withDivider = false,
  isCentered = false,
  isHero = false,
  minHeight = 0,
  isBackgroundPositionBottom = false,
  id,
  style,
}) => {
  const backgroundImageStyle = setBackgroundImageStyle(isPage, backgroundImage);

  return (
    <Styled.Container
      id={id}
      $isPage={isPage}
      $withBanner={withBanner}
      $isHero={isHero}
      style={{ ...backgroundImageStyle, ...style }}
      $minHeight={minHeight}
      $isBackgroundPositionBottom={isBackgroundPositionBottom}
    >
      <Styled.ContentContainer $isCentered={isCentered} $isHero={isHero}>
        {children}
      </Styled.ContentContainer>
      {withDivider && <StyledDivider $isPage={isPage} />}
    </Styled.Container>
  );
};
