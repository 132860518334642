import { Chip as ChipLib } from "@mui/material";

import styled from "styled-components";

export const Chip = styled(ChipLib)`
  border-radius: 64px;
  background: #4b3727;

  .MuiChip-label {
    color: ${({ theme }) => theme.palette.textColor.common};
    font-family: Raleway;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
  }

  .MuiChip-deleteIcon {
    circle,
    path {
      transition: fill 0.25s ease-in-out, stroke 0.25s ease-in-out;
    }
    circle {
      fill: #4b3726;
      stroke: rgb(255, 220, 168);
    }

    path {
      fill: rgb(255, 220, 168);
    }

    &:hover {
      circle {
        fill: #ffdca8;
        stroke: #ffe7c4;
      }

      path {
        fill: #59412e;
      }
    }
  }
`;
