import React, { createContext, FC, ReactNode, useContext, useEffect, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

import { CssBaseline, Stack } from "@mui/material";

import { useAuth } from "@hooks/auth";
import { useCountDownPlayback } from "@hooks/useCountDownPlayback";

import { limitDownloadsAmount } from "@store/actions/downloads";
import { getFavorites } from "@store/actions/favorites/favorites";

import LoginDialog from "@components/LoginDialog";
import RecoveryDialog from "@components/RecoveryDialog";
import SignUpDialog from "@components/SignUpDialog";
import { UnauthorizedUserDialog } from "@components/UnauthorizedUserDialog";
import { PlayerContext } from "@layouts/PlayerLayout/utils";
import { IUser } from "@models/models";
import { ERoutes } from "@utils/constants";

import GlobalStyle from "@/components/GlobalStyle";
import { useIsDesktop } from "@/hooks/layout";
import SecondaryBurgerMenu from "@/layouts/components/SecondaryBurgerMenu";
import Footer from "@/layouts/components/SecondFooter";
import SecondHeader from "@/layouts/components/SecondHeader";
import { my } from "@/store/actions/user";
import { useAppDispatchUnwrap } from "@/store/hooks";

type HandleOpenDialogType = (isLogin: boolean, isClosable?: boolean, isUnauthorized?: boolean) => void | null;

export const HandleOpenDialog = createContext<HandleOpenDialogType>(null);

interface ISecondRoot {
  isNewsletter?: boolean;
  children: ReactNode;
}

export enum ERecovery {
  FirstStep = 1,
  SecondStep = 2,
  ThirdStep = 3,
}

export enum ESignUp {
  Links = 0,
  ClassifyContent,
  UseCases,
  AcceptTerms,
  Newsletter,
  VerifyEmail,
  Welcome,
}

enum ESignUpFlow {
  OpenDialog = "openDialog",
  OpenRecoveryDialog = "openRecoveryDialog",
  OpenSignUpDialog = "OpenSignUpDialog",
  RecoveryStep = "recoveryStep",
  SignUpStep = "signUpStep",
  Login = "login",
  IS_CLOSABLE = "IS_CLOSABLE",
  UNAUTHORIZED_USER_DIALOG = "UNAUTHORIZED_USER_DIALOG",
}

type SignUpFlowAction =
  | {
      type:
        | ESignUpFlow.OpenDialog
        | ESignUpFlow.OpenRecoveryDialog
        | ESignUpFlow.OpenSignUpDialog
        | ESignUpFlow.Login
        | ESignUpFlow.IS_CLOSABLE
        | ESignUpFlow.UNAUTHORIZED_USER_DIALOG;
      payload: boolean;
    }
  | {
      type: ESignUpFlow.RecoveryStep | ESignUpFlow.SignUpStep;
      payload: number;
    };

interface SignUpFlowState {
  isDialogOpen: boolean;
  isRecoveryDialogOpen: boolean;
  isSignUpDialogOpen: boolean;
  isLogin: boolean;
  isClosable: boolean;
  recoveryStep: number;
  signUpStep: number;
  unauthorizedDialogOpen: boolean;
}

const signUpFlowReducer = (state: SignUpFlowState, action: SignUpFlowAction) => {
  const { type, payload } = action;
  switch (type) {
    case ESignUpFlow.OpenDialog:
      return {
        ...state,
        isDialogOpen: payload,
      };
    case ESignUpFlow.OpenRecoveryDialog:
      return {
        ...state,
        isRecoveryDialogOpen: payload,
      };
    case ESignUpFlow.OpenSignUpDialog:
      return {
        ...state,
        isSignUpDialogOpen: payload,
      };
    case ESignUpFlow.Login:
      return {
        ...state,
        isLogin: payload,
      };
    case ESignUpFlow.RecoveryStep:
      return {
        ...state,
        recoveryStep: payload,
      };
    case ESignUpFlow.SignUpStep:
      return {
        ...state,
        signUpStep: payload,
      };
    case ESignUpFlow.IS_CLOSABLE:
      return {
        ...state,
        isClosable: payload,
      };
    case ESignUpFlow.UNAUTHORIZED_USER_DIALOG:
      return {
        ...state,
        unauthorizedDialogOpen: payload,
      };
    default:
      return state;
  }
};

const signUpStep = (me: IUser) => {
  switch (true) {
    case !me.socialLinks:
      return ESignUp.Links;
    case !me.categories.length:
      return ESignUp.ClassifyContent;
    case !me.useCases:
      return ESignUp.UseCases;
    case !me.hasAcceptedTerms:
      return ESignUp.AcceptTerms;
    case !me.hasNewsletter:
      return ESignUp.Newsletter;
    case !me.isEmailVerified:
      return ESignUp.VerifyEmail;
    default:
      return ESignUp.Links;
  }
};

const SecondRootLayout: FC<ISecondRoot> = ({ children, isNewsletter = true }) => {
  useTranslation(["navigation", "questionFlow"]);
  const isDesktop = useIsDesktop();
  const [burgerMenu, setBurgerMenuOpen] = useState(false);
  const { isUserCanPlay } = useCountDownPlayback();

  const { myData } = useAuth();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [state, dispatchReducer] = useReducer(signUpFlowReducer, {
    isDialogOpen: false,
    isRecoveryDialogOpen: false,
    isSignUpDialogOpen: false,
    recoveryStep: ERecovery.FirstStep,
    signUpStep: ESignUp.Links,
    isLogin: false,
    isClosable: isUserCanPlay,
    unauthorizedDialogOpen: false,
  });
  const { openLoginDialog, setOpenLoginDialog } = useContext(PlayerContext);

  const token = searchParams.get("token");

  const dispatch = useAppDispatchUnwrap();

  useEffect(() => {
    const getData = async () => {
      await Promise.all([dispatch(my()), dispatch(getFavorites()), dispatch(limitDownloadsAmount())]);
    };

    getData();
  }, []);

  useEffect(() => {
    if (!myData) return;

    if (
      !myData.isEmailVerified ||
      !myData.socialLinks ||
      !myData.categories.length ||
      !myData.useCases ||
      !myData.hasAcceptedTerms ||
      !myData.hasNewsletter
    ) {
      if (!myData.isEmailVerified) {
        navigate(ERoutes.Catalog);
      }

      dispatchReducer({
        type: ESignUpFlow.SignUpStep,
        payload: signUpStep(myData),
      });

      dispatchReducer({
        type: ESignUpFlow.OpenSignUpDialog,
        payload: true,
      });
    }
  }, [
    myData?.isEmailVerified,
    myData?.socialLinks,
    JSON.stringify(myData?.socialLinks),
    JSON.stringify(myData?.categories),
    JSON.stringify(myData?.useCases),
    myData?.hasAcceptedTerms,
    myData?.hasNewsletter,
  ]);

  useEffect(() => {
    if (state.signUpStep > ESignUp.Welcome) {
      dispatchReducer({
        type: ESignUpFlow.OpenSignUpDialog,
        payload: false,
      });
    }
  }, [state.signUpStep]);

  useEffect(() => {
    if (token) {
      dispatchReducer({
        type: ESignUpFlow.RecoveryStep,
        payload: ERecovery.ThirdStep,
      });

      dispatchReducer({
        type: ESignUpFlow.OpenRecoveryDialog,
        payload: true,
      });
    }
  }, [token]);

  const handleOpenDialog = (isLogin: boolean, isClosable?: boolean, isUnauthorized?: boolean) => {
    if (isUnauthorized) {
      return dispatchReducer({
        type: ESignUpFlow.UNAUTHORIZED_USER_DIALOG,
        payload: true,
      });
    }
    dispatchReducer({
      type: ESignUpFlow.UNAUTHORIZED_USER_DIALOG,
      payload: false,
    });
    if (!isClosable) {
      dispatchReducer({
        type: ESignUpFlow.IS_CLOSABLE,
        payload: isClosable,
      });
    }
    if (isLogin) {
      dispatchReducer({
        type: ESignUpFlow.Login,
        payload: true,
      });
    } else {
      dispatchReducer({
        type: ESignUpFlow.Login,
        payload: false,
      });
    }
    dispatchReducer({
      type: ESignUpFlow.OpenDialog,
      payload: true,
    });
  };

  useEffect(() => {
    if (openLoginDialog) {
      handleOpenDialog(false, true, true);
    }
  }, [openLoginDialog]);

  const handleNextStep = () => {
    dispatchReducer({
      type: ESignUpFlow.RecoveryStep,
      payload: state.recoveryStep + 1,
    });
  };

  const handleNextStepSignUp = () => {
    dispatchReducer({
      type: ESignUpFlow.SignUpStep,
      payload: state.signUpStep + 1,
    });
  };

  return (
    <>
      <CssBaseline />
      <GlobalStyle />

      <Stack width="100%" height="100%">
        {isDesktop && <SecondHeader handleOpenDialog={handleOpenDialog} />}

        {!isDesktop && (
          <SecondaryBurgerMenu
            burgerMenu={burgerMenu}
            handleOpenDialog={handleOpenDialog}
            openBurgerMenu={() => setBurgerMenuOpen(true)}
            closeBurgerMenu={() => setBurgerMenuOpen(false)}
          />
        )}

        <>
          <HandleOpenDialog.Provider
            value={(isLogIn, isClosable, isUnauthorized) => {
              handleOpenDialog(isLogIn, isClosable, isUnauthorized);
            }}
          >
            {children}
          </HandleOpenDialog.Provider>
        </>

        <Footer isNewsletter={isNewsletter} />
      </Stack>

      <LoginDialog
        open={state.isDialogOpen}
        isClosable={state.isClosable}
        handleClose={() => {
          dispatchReducer({
            type: ESignUpFlow.OpenDialog,
            payload: false,
          });
          if (openLoginDialog) {
            setOpenLoginDialog();
          }
        }}
        handleOpenRecoveryDialog={() => {
          dispatchReducer({
            type: ESignUpFlow.OpenRecoveryDialog,
            payload: true,
          });
        }}
        isLogin={state.isLogin}
        handleChange={() => {
          dispatchReducer({
            type: ESignUpFlow.Login,
            payload: !state.isLogin,
          });
        }}
      />

      <RecoveryDialog
        open={state.isRecoveryDialogOpen}
        handleClose={() => {
          dispatchReducer({
            type: ESignUpFlow.OpenRecoveryDialog,
            payload: false,
          });
        }}
        step={state.recoveryStep}
        handleNextStep={handleNextStep}
        resetToken={token}
      />

      <SignUpDialog
        open={state.isSignUpDialogOpen}
        handleClose={() => {
          dispatchReducer({
            type: ESignUpFlow.OpenSignUpDialog,
            payload: false,
          });
        }}
        handleNextStep={handleNextStepSignUp}
        step={state.signUpStep}
      />
      <UnauthorizedUserDialog
        open={state.unauthorizedDialogOpen}
        handleClose={() => {
          dispatchReducer({
            type: ESignUpFlow.UNAUTHORIZED_USER_DIALOG,
            payload: false,
          });
          if (openLoginDialog) {
            setOpenLoginDialog();
          }
        }}
        handleLogin={() => handleOpenDialog(true)}
        handleSignUp={() => handleOpenDialog(false)}
      />
    </>
  );
};

export default SecondRootLayout;
