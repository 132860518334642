import { Link } from "react-router-dom";

import { Stack } from "@mui/material";

import styled from "styled-components";

import { isSmallDesktop } from "@/hooks/layout";

export const OuterBorderStack = styled(Stack)`
  width: 100%;
  height: 365px;

  display: inline-block;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    padding: 2px;
    border-radius: 16px;
    background: ${({ theme }) => theme.palette.gradientButton.mainGradient};
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }

  ${isSmallDesktop} {
    height: 304px;
    padding: 0;
  }
`;

export const InnerBorderStack = styled(Stack)`
  width: 100%;
  height: 100%;

  padding: 16px 16px 0;
  border-radius: 16px;
  background: linear-gradient(180deg, rgba(58, 44, 36, 0.75) 0%, rgba(77, 55, 39, 0.4) 100%);

  ${isSmallDesktop} {
    padding: 24px 24px 0;
  }
`;

export const RLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;
