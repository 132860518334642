import { useEffect, useState } from "react";

import { useMediaQuery, useTheme } from "@mui/material";

export const isWideDesktop = `@media only screen and (min-width: 2260px)`;
export const isDesktop = `@media only screen and (min-width: 1810px)`;
export const isSmallDesktop = `@media only screen and (min-width: 1250px)`;
export const isSmallMobile = `@media only screen and (max-width: 320px)`;
export const isMobile = `@media only screen and (max-width: 1024px)`;

export const useIsDesktop = (): boolean => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.up("md"));
};

export const useIsBigDesktop = (): boolean => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.up("lg"));
};

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
};
