import { FC, PropsWithChildren } from "react";

import { SxProps } from "@mui/material";

import { Button as GradientButton } from "@ui/index";

import { Donation } from "@assets/svg";

type Props = {
  isFullWidth: boolean;
  sx?: SxProps;
  handler: () => void;
};

export const DonationButton: FC<Props & PropsWithChildren> = ({ handler, isFullWidth, children, sx }) => {
  return (
    <GradientButton
      isFullWidth={isFullWidth}
      onClick={() => handler()}
      sx={{
        display: "flex !important",
        alignItems: "center",
        gap: "8px",
        padding: "16px",
        height: "fit-content !important",
        ...sx,
      }}
    >
      <Donation />
      {children}
    </GradientButton>
  );
};
