import { MutableRefObject, SyntheticEvent } from "react";

import { TTrack } from "@store/actions/track";

import { TWaveSurferPlayerState, WaveSurferPlayerAction } from "@components/BottomPlayer/utils";
import { ERoutes } from "@utils/constants";

export enum AvailableFor {
  AVAILABLE_FOR = "Available For",
  LICENSING = "Licensing",
}

export type TPlayerProps = {
  state: TWaveSurferPlayerState;
  bottomTrackData: TTrack;
  isVolumeTouched: boolean;
  currentTimeRef: MutableRefObject<HTMLDivElement>;
  containerRef: MutableRefObject<HTMLDivElement>;
  isAvailableFor: (availableFor: AvailableFor) => boolean;
  handleNavigate: (route: ERoutes, id: string, isException?: boolean) => void;
  dispatchReducer: (value: WaveSurferPlayerAction) => void;
  handleNavigateComposer: (composerName: string) => void;
  onPlayClick: () => void;
  handleVolumeMute: () => void;
  onVolumeChange: (_e: Event | SyntheticEvent<Element, Event>, value: number | number[]) => void;
  handleDownload: () => void;
  handleLicensing: () => void;
  handleLike: () => void;
};
