import { FC } from "react";

import { Box } from "@mui/material";

import { Text } from "@ui/typography";

import theme from "@theme/theme";

type CutomToastProps = {
  message: string;
  closeToast: () => void;
};

export const CustomToast: FC<CutomToastProps> = ({ closeToast, message }) => (
  <Box
    onClick={closeToast}
    sx={{
      color: `${theme.palette.header.textColor} `,
    }}
  >
    <Text fontWeight={500} isFullWidth textAlign="center">
      {message}
    </Text>
  </Box>
);
