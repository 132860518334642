import { RefObject, useEffect, useState } from "react";

import { useIsDesktop } from "@hooks/layout";

import WaveSurfer, { WaveSurferOptions } from "wavesurfer.js";
import Hover from "wavesurfer.js/dist/plugins/hover";

const options: Partial<WaveSurferOptions> = {
  waveColor: "#93755f",
  progressColor: "#f8d6a7",
  cursorColor: "#f8d6a7",
  barHeight: 0.75,
  normalize: true,
  height: 32,
  fillParent: true,
  dragToSeek: true,
};

export type TUseWavesurfer = {
  containerRef: RefObject<HTMLDivElement>;
  peaks?: number[];
  url?: string;
  media?: HTMLMediaElement;
  isTrackPage?: boolean;
};

export const useWavesurfer = ({ url, peaks, containerRef, media, isTrackPage }: TUseWavesurfer) => {
  const isDesktop = useIsDesktop();
  const [wavesurfer, setWavesurfer] = useState<WaveSurfer | null>(null);

  const updateTrackWaveform = isTrackPage ? window.location.pathname.split("/").pop() : undefined;

  useEffect(() => {
    if (!containerRef?.current) return;

    const ws = WaveSurfer.create({
      ...options,
      url,
      media,
      container: containerRef.current,
      peaks: peaks ? [peaks] : [[]],
      plugins: [
        Hover.create({
          lineColor: "rgba(99, 68, 45, 1)",
          lineWidth: 1,
          labelBackground: "rgba(99, 68, 45, 0.7)",
          labelColor: "#E0D2C1",
          labelSize: "14px",
        }),
      ],
    });

    const subscription = ws.on("finish", () => {
      ws.setTime(0);
    });

    setWavesurfer(ws);

    return () => {
      const isPaused = media?.paused;
      const audioElement = document.createElement("audio");

      ws.setMediaElement(audioElement);
      ws.destroy();
      if (isTrackPage && !isPaused) {
        media?.play();
      }
      subscription();
    };
  }, [url, media, isDesktop, updateTrackWaveform]);

  return wavesurfer;
};
