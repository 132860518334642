import { FC, PropsWithChildren } from "react";

import { Box, SxProps } from "@mui/material";

import { Button as GradientButton } from "@ui/index";

import { LicenseTestShield } from "@assets/svg";

type Props = {
  isFullWidth: boolean;
  handler: () => void;
  sx?: SxProps;
  textSx?: SxProps;
};

export const LicenseTestButton: FC<Props & PropsWithChildren> = ({ handler, isFullWidth, children, sx, textSx }) => {
  return (
    <GradientButton
      isFullWidth={isFullWidth}
      isOutlined
      onClick={() => handler()}
      sx={{
        display: "flex !important",
        alignItems: "center",
        gap: "5px",
        padding: "16px",
        height: "fit-content !important",
        ...sx,
      }}
    >
      <LicenseTestShield />
      <Box sx={textSx}>{children}</Box>
    </GradientButton>
  );
};
