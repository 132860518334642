import { Avatar } from "@mui/material";

import styled from "styled-components";

type TAvatarWrapper = {
  $isSmall: boolean;
};

export const AvatarWrapper = styled(Avatar)<TAvatarWrapper>`
  && {
    width: ${({ $isSmall }) => ($isSmall ? "24px" : "34px")};
    height: ${({ $isSmall }) => ($isSmall ? "24px" : "34px")};
    border: 1px solid #ad8d75;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    & > svg {
      transform: translateX(1px);
    }

    &:hover {
      border-color: #ffdca8;
      box-shadow: 0 0 8px 0 rgba(254, 209, 154, 0.4);
    }
  }
`;
