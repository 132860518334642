import React, { FC, Fragment } from "react";

import { SxProps } from "@mui/material";

import { InfinityLogoLoop } from "@components/InfinityLogoLoop";
import uniqueId from "lodash/uniqueId";

import {
  FacebookIcon,
  InstagramIcon,
  RumbleIcon,
  SnapchatIcon,
  ThreadsIcon,
  TikTokIcon,
  TwitchIcon,
  TwitterIcon,
  YoutubeIcon,
} from "@/assets/svg/socials";

const getLogo = () => [
  <FacebookIcon />,
  <InstagramIcon />,
  <RumbleIcon />,
  <SnapchatIcon />,
  <ThreadsIcon />,
  <TikTokIcon />,
  <TwitchIcon />,
  <TwitterIcon />,
  <YoutubeIcon />,
];

type SocialsCarouselProps = {
  sx?: SxProps;
};

const SocialsCarousel: FC<SocialsCarouselProps> = ({ sx }) => {
  return (
    <InfinityLogoLoop sx={sx}>
      <>
        {getLogo().map((el) => (
          <Fragment key={uniqueId("infinity-logo_")}>{el}</Fragment>
        ))}
      </>
    </InfinityLogoLoop>
  );
};

export default SocialsCarousel;
